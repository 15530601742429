import React, { FC, useState } from "react";
import { View } from "react-native";
import WebButton from "../styled/WebButton";
import InformationField from "../styled/InformationField";
import EmptyView from "../styled/EmptyView";

type PlasticCollectionDetailProps = {
  plasticCollection: PlasticCollection;
  hideWeight?: boolean;
};

// TODO: This should come autogenerated from the backend!
export type PlasticCollection = {
  id: string;
  clusterId: string;
  requesterId: string;
  clusterName: string;
  recipientPartnerId: string;
  numberOfUnits: number;
  streetAddress: string;
  city: string;
  zipCode: string;
  isFirstCollection: boolean;
  isLastCollection: boolean;
  companyName?: string;
  comment?: string;
  scheduledPickupDate?: string;
  weight?: number;
  collectionStatus: "pending" | "scheduled" | "delivered" | "received";
};

// TODO: Unify this with BatchDetail!!
const PlasticCollectionDetail: FC<PlasticCollectionDetailProps> = ({
  plasticCollection,
  hideWeight = false,
  children,
}) => {
  const {
    streetAddress,
    zipCode,
    city,
    numberOfUnits,
    comment,
    weight,
    clusterName,
  } = plasticCollection;
  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = () => setShowDetails(!showDetails);

  const title = `${streetAddress} for cluster ${clusterName}`;
  const addressString =
    streetAddress && (zipCode || city)
      ? `${streetAddress || ""}, ${zipCode || ""} ${city || ""}`
      : `${streetAddress || ""} ${zipCode || ""} ${city || ""}`;

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <WebButton
          icon={{
            src: require("../../assets/icons/dropdown_grey.png"),
            width: 29,
            height: 29,
          }}
          onPress={toggleDetails}
          isSelected={showDetails}
          text={title}
        />
      </View>
      {showDetails && (
        <View style={styles.detailsContainer}>
          {addressString && (
            <InformationField value={addressString} style={[styles.line]} />
          )}
          {numberOfUnits !== undefined && numberOfUnits !== null && (
            <InformationField
              value={`${numberOfUnits} enheder`}
              style={[styles.line]}
            />
          )}
          {comment && (
            <InformationField
              value={comment}
              style={[styles.line, styles.commentField]}
            />
          )}
          {weight && !hideWeight && (
            <InformationField value={`${weight} kg`} style={styles.line} />
          )}
          {children}
        </View>
      )}
    </View>
  );
};

type Props = {
  plasticCollections: PlasticCollection[];
  sorting?: {
    displayName: string;
    sortState: [boolean, (newValue: boolean) => void];
  };
  hideWeight?: boolean;
  children?: (plasticCollection: PlasticCollection) => React.ReactNode;
};

const PlasticCollectionsDetails: FC<Props> = ({
  plasticCollections,
  sorting,
  hideWeight = false,
  children,
}) => {
  const [sort, setSort] = sorting ? sorting.sortState : [false, undefined];
  const toggleSort = setSort ? () => setSort(!sort) : undefined;

  return plasticCollections.length === 0 ? (
    <EmptyView />
  ) : (
    <View>
      {sorting && (
        <WebButton
          text={`Sorter efter ${sorting.displayName}`}
          icon={{
            src: require("../../assets/icons/calendar_grey.png"),
            width: 25,
            height: 25,
          }}
          onPress={toggleSort}
          isSelected={sort}
          style={styles.filterButton}
        />
      )}
      {plasticCollections.map((collection, index) => {
        const isLastCollection = index === plasticCollections.length - 1;

        return (
          <View
            style={!isLastCollection ? styles.line : undefined}
            key={collection.id}
          >
            <PlasticCollectionDetail
              plasticCollection={collection}
              hideWeight={hideWeight}
            >
              {children && children(collection)}
            </PlasticCollectionDetail>
          </View>
        );
      })}
    </View>
  );
};

const styles = {
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  line: {
    marginBottom: 23,
  },
  detailsContainer: {
    marginLeft: 14,
    flex: 1,
  },
  filterButton: {
    flex: 1,
    marginBottom: 23,
  },
  commentField: {
    height: 68,
    textAlignVertical: "center" as "center",
  },
};

export default PlasticCollectionsDetails;
